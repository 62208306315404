import React from 'react'
import styled from 'styled-components'
import "./herocomp.css"

const Herotext = styled.div`
 width: 100% ;
  height: calc(100vh - 82px);

  position: relative;

  color: white;
  font-family: 'Archivo', sans-serif;
  font-size: 48px;
  background-image: url("${p => p.image}");
  background-size: cover;
  display: block;
  text-align: right;
  padding-top: 2%;
  padding-right: 2%;
  text-shadow: 4px 4px 13px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 1160px){
    font-size: 37px;
  }

`


export default function Herocomp({imageinput}) {
  return (
    <div className="hero">
    <Herotext image={imageinput}></Herotext> 
    
    </div>
  )
}
