import React from 'react'
import { Link } from "gatsby";
import "./topbar.css"


export default function TopBar({item}) {
  return (
    <div className="top">
            <div className="topCenter">
            <ul className="topSheet">
              <li className="topSheetItem">
                <Link to="/publicaciones/articulos" style={{color: "rgba(19, 138, 146, 1)" , textAlign: "center"}}>ARTÍCULOS</Link>
               { item ===1 && <svg width="196" height="19" viewBox="0 0 196 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_243_22)">
<path d="M2 17H179.5L194 2.5" stroke="#30999F"/>
<path d="M194 4C195.105 4 196 3.10457 196 2C196 0.89543 195.105 0 194 0C192.895 0 192 0.89543 192 2C192 3.10457 192.895 4 194 4Z" fill="#0B9299"/>
<path d="M2 19C3.10457 19 4 18.1046 4 17C4 15.8954 3.10457 15 2 15C0.89543 15 0 15.8954 0 17C0 18.1046 0.89543 19 2 19Z" fill="#0B9299"/>
</g>
<defs>
<clipPath id="clip0_243_22">
<rect width="196" height="19" fill="white"/>
</clipPath>
</defs>
</svg>}

              </li>
              
              <li className="topSheetItem">
                <Link to="/publicaciones/redes" style={{color: "rgba(19, 138, 146, 1)"}}>REDES SOCIALES</Link>
                { item ===2 && <svg width="196" height="19" viewBox="0 0 196 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_243_22)">
<path d="M2 17H179.5L194 2.5" stroke="#30999F"/>
<path d="M194 4C195.105 4 196 3.10457 196 2C196 0.89543 195.105 0 194 0C192.895 0 192 0.89543 192 2C192 3.10457 192.895 4 194 4Z" fill="#0B9299"/>
<path d="M2 19C3.10457 19 4 18.1046 4 17C4 15.8954 3.10457 15 2 15C0.89543 15 0 15.8954 0 17C0 18.1046 0.89543 19 2 19Z" fill="#0B9299"/>
</g>
<defs>
<clipPath id="clip0_243_22">
<rect width="196" height="19" fill="white"/>
</clipPath>
</defs>
</svg>}
              
              </li>
            </ul>
            </div>
   </div>
  )
}
