import React, { useState, useEffect } from 'react'
import MainLayout from '../../../components/Layouts/MainLayout'
import Herocomp from '../../../components/Publicaciones/herocomp'
import TopBar from '../../../components/Publicaciones/TopBar'
import useContentful from '../../../utils/useContentful'
import "./redes.css"
import styled from "styled-components"

const Container = styled.div`
  &.animate {
    animation: fade-up 2.5s;
  }
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(50px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }


`

const Cardcomponent2 = ({image, title, text, linkpoint, icosm, large}) => {
  const [show, setShow] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        if(window.screen.width > 600){
        if (window.scrollY > 100) {
          setShow(true);
        } else {
          setShow(false);
        }}
        else{
          setShow(true);
        }
      };
      window.addEventListener("scroll", handleScroll);
      // Cleanup function
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  if (large == false){

    

  return(
    <Container className={show ? "animate" : ""}>

    <div className="card2">
      <a href={linkpoint} target="_blank"><img className="image2" src={image}/></a>
    <div className="textcard2">
      
      <a href={linkpoint} target="_blank" style={{color: "black"}}><p className="text2">{text}</p></a>
      <a href={linkpoint} target="_blank" ><h1 className="title2">{title}</h1></a>
      <a href={linkpoint} target="_blank" ><img className="icon2" src={icosm}/></a>
    </div>
    </div>
    </Container>

  )
}
else{
  return(
    <Container className={show ? "animate" : ""}>
    <div className="card2L">
    <a href={linkpoint} target="_blank"><img className="image2L" src={image}/></a>
  <div className="textcard2L">
    
    <a href={linkpoint} target="_blank" style={{color: "black"}}><p className="text2L">{text}</p></a>
    <a href={linkpoint} target="_blank" ><h1 className="title2L">{title}</h1></a>
    <a href={linkpoint} target="_blank" ><img className="icon2L" src={icosm}/></a>
  </div>
  </div>
  </Container>
  )

}
}


const ArticuloEntryId = "1cz49tBW52Ik47wuHuBA4i";

const Redes =(props) => {
  const [data , setData] = useState()
  const { data: articulo, fetched } = useContentful({ id: ArticuloEntryId});




  useEffect(()=>{

    
    
    const lala2 = articulo?.fields.redessociales.map((item,index) => {
     
        return(
          {
            image: item.fields.cardImage.fields.file.url,
            title: item.fields.title,
            text: item.fields.text,
            icosm: icons[item.fields.socialMedia],
            linkpoint: item.fields.link ,
            large: item.fields.bigCard
          }
        )
    })
   
    setData(lala2);
    
  }
  
  , [articulo])


 const icons = {
  Facebook: "/uil_facebook.svg",
  Instagram: "/ri_instagram-fill.svg",
  Linkedin : "/mdi_linkedin.svg",
  Youtube:"/mdi_youtube.svg"

 }




  return(
    <>
    {fetched && articulo?.fields.mostrarSeccion? <MainLayout active={5}>
     <Herocomp imageinput={articulo.fields.hero.fields.file.url}></Herocomp>
      <TopBar item={2}></TopBar>
  
    <div className="art2Container2">
    <div className="art3Container2">
      {data.map((item) => {
        return (
          <Cardcomponent2 image={item.image} title={item.title} text={item.text} linkpoint={item.linkpoint} icosm={item.icosm} large={item.large}></Cardcomponent2>
        )
      }
      )}
    </div>
    </div>
   
    </MainLayout>:
 <div
 style={{
   width: "100vw",
   height: "100vh",
   display: "flex",
   justifyContent: "center",
   marginTop: "0px",
   padding: "15%",
 }}
>
 {" "}
 <svg
   version="1.1"
   id="L9"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="0px"
   viewBox="0 0 100 100"
   enableBlackground="new 0 0 0 0"
   xmlSpace="preserve"
 >
   <path
     fill="#008991"
     d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
   >
     <animateTransform
       attributeName="transform"
       attributeType="XML"
       type="rotate"
       dur="1s"
       from="0 50 50"
       to="360 50 50"
       repeatCount="indefinite"
     />
   </path>
 </svg>
</div>
    }
    </>
  );
}

export default Redes;